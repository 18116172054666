.hover-opacity
  +media('pc')
    transition opacity .5s
    &:hover
      opacity .6

.hover-zoom
  +media('pc')
    .img
      overflow hidden

      img
        backface-visibility hidden
        display inline-block
        transition transform 5s cubic-bezier(0.19, 1, 0.22, 1)

    &:hover
      img
        transform scale(1.1)
