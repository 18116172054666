// ==========================================================================
// おしらせ
// ==========================================================================
.section-news
  margin-bottom 73px
  margin-top -30px
  padding-top 30px

  +media('pc')
    margin-bottom 59px
    margin-top -100px
    padding-top 100px

  .heading
    margin-bottom 29px
    font-vw(46, 46)

    +media('pc')
      font-rem(24, 24)

  .news-list
    border-bottom 1px solid #b5cdce

    +media('pc')
      container-centering(840)

    .item
      border-top 1px solid #b5cdce

      a
        color #1c5a9b

      .item-inner
        display flex
        flex-direction column
        padding 13px 0

        +media('pc')
          flex-direction row
          padding 12px 0

        .date, .text
          transition all 0.5s

        .date
          font-vw(24, 24)
          color #000000
          +media('pc')
            font-rem(12, 24)
          +media('sp')
            margin-bottom 9px

        .text
          font-vw(28, 42)
          flex-shrink 2

          +media('pc')
            font-rem(14, 24)
            margin-left 18px

      a:hover
        .date
          opacity 0.6

        .text
          color #609ad7