// ==========================================================================
// Step List
// ==========================================================================
.step-list
  flex()
  justify-content center
  margin 0 auto 46px
  position relative

  &::before
    absolute top _vw(28, $size-sp) right left
    background #ffffff
    content ''
    display block
    margin 0 auto
    size _vw(448, $size-sp) 2px

  +media('pc')
    margin-bottom 76px
    width 312px

    &::before
      size 100% 5px
      top 16px

.step-list-item
  position relative

  &:not(:first-child)
    margin-left _vw(134, $size-sp)

  +media('pc')
    &:not(:first-child)
      margin-left 102px

  .number, .text
    display block
    font-weight 500
    text-align center

  .number
    centering-flex()
    font-vw(28, 28, 80)
    background #ffffff
    border-radius 50%
    box-sizing border-box
    color #aeaeae
    font-weight 500
    margin 0 auto 5px
    padding 0 0 2px 1px
    size _vw(60, $size-sp)

    +media('pc')
      font-rem(16, 16, 80)
      size 36

  .text
    font-vw(22, 22, 80)
    absolute right bottom -16px left
    margin 0 calc(50% - 50vw)
    pointer-events none

    +media('pc')
      font-rem(12, 12, 80)
      bottom -17px

  &:nth-child(1)
    .number
      padding-left 2px

  &.located, &.finish
    .number
      background #1c5a9b
      border 2px solid #ffffff
      color #ffffff

      +media('pc')
        border-width 3px

    .text
      color #1c5a9b

// ==========================================================================
// Form
// ==========================================================================
.contact-form
  position relative

.contact-form-inner
  container-centering(_vw(690, $size-sp))
  background #ffffff
  box-sizing border-box
  padding 19px _vw(30, $size-sp) _half(90)

  +media('pc')
    max-width 840px
    padding 18px 60px 90px
    width _percentage(840, $size-pc)

  .text-required
    font-vw(22, 22)
    color #d90038
    font-weight 500
    padding-left 6px
    relative left _vw(30, $size-sp)

    &::before
      font-vw(22, 22)
      absolute top 3px left
      color #d90038
      content '*'
      display block
      margin auto 0
      vertical-align middle

    +media('pc')
      font-rem(12, 12, 80)
      left -40px
      margin-bottom 55px
      padding-left 8px

      &::before
        font-rem(12, 12)

  .item
    position relative

    &:not(:first-child)
      margin-top _half(80)

    +media('pc')
      &:not(:first-child)
        margin-top 24px

  .item-inner
    flex()
    box-sizing border-box
    flex-direction column
    width 100%

    +media('sp')
      padding 0 _vw(30, $size-sp)

    +media('pc')
      align-items center
      flex-direction row

  .key, .value
    display block

  .key
    font-vw(28, 28)
    font-weight bold
    margin-bottom _half(28)
    position relative

    +media('pc')
      font-rem(14, 14, 80)
      margin 0
      max-width 286px
      width _percentage(286, 720)

    @media only screen and (min-width: $break-point-pc px) and (max-width: 1023px)
      font-rem(12, 12, 80)

  .value
    position relative

    +media('pc')
      max-width 434px
      width _percentage(434, 720)

  .required
    &::before
      absolute top right -0.7em
      color #d90038
      content '*'
      display block

    +media('pc')
      &::before
        font-rem(19, 19)
        right 0

  .text-error
    color #d90038
    display none

    +media('sp')
      font-vw(22, 22)
      absolute bottom -1.3em left

    +media('pc')
      font-rem(12, 12, 80)
      absolute top -1.2em left

  .item.error
    .text-error
      display block

  .select-box
    icon-arrow-type-1(7, 1.5, #aeaeae)
    background #f2f2f2
    border-radius 5px
    box-sizing border-box
    position relative

    &::before
      absolute top bottom right 12px
      content ''
      left auto
      margin auto 0
      pointer-events none
      transform rotate(135deg) translateY(1px)

    +media('pc')
      width calc(100% - 15px)

      &::before
        right 15px

    .select
      padding _vw(30, $size-sp) _half(60) _vw(30, $size-sp) _half(30)

      +media('pc')
        padding 15px 42px 15px 20px

    .select.disabled
      color #aeaeae

  .error
    .select-box
      padding: 0 !important

  .input, .textarea, .select
    background #f2f2f2
    border none
    border-radius 5px
    box-sizing border-box
    display block
    font-family inherit
    font-size 100%
    margin 0
    outline none
    width 100%
    -webkit-appearance none

    &::-webkit-input-placeholder
      color #aeaeae
      padding-top 0.2em
      // position relative
      // top 2px

    &:-ms-input-placeholder
      color #aeaeae

    &:-webkit-autofill
      -webkit-box-shadow 0 0 0 1000px #f2f2f2 inset

    &::-ms-clear
      display none

    &::placeholder
      color #aeaeae
      position relative
      +media('sp')
        font-vw(28, 28)

      +media('pc')
        font-rem(14, 14, 80)

      @media only screen and (min-width: $break-point-pc px) and (max-width: 1023px)
        font-rem(12, 12, 80)

    +media('sp')
      font-vw(28, 28)

    +media('pc')
      font-rem(14, 14, 80)

    @media only screen and (min-width: $break-point-pc px) and (max-width: 1023px)
      font-rem(12, 12, 80)

  .input, .textarea
    color #000
    // padding _vw(28, $size-sp) _half(80) _vw(24, $size-sp) _half(30)
    padding _vw(26, $size-sp) _half(80) _vw(26, $size-sp) _half(30)

  .input[type='text'], .input[type='tel'], .input[type='email']
    +media('pc')
      line-height 20px
      padding 11px 42px 11px 20px
      width calc(100% - 15px)

  .textarea
    lineheight(28, 38)
    box-sizing border-box
    height _half(450)

    +media('pc')
      height 210px
      padding 14px 20px
      width calc(100% - 15px)

  .item-select-box
    padding-bottom _half(82)

    &::before
      absolute bottom left
      background #d7d7d7
      content ''
      display block
      size 100% 2px

    +media('pc')
      margin-bottom 43px
      padding-bottom 42px

      &::before
        left 0
        size 100% 1px

  .item-comment
    padding-top _half(82)

    &::before
      absolute top left _half(-30px)
      background #d7d7d7
      content ''
      display block
      size calc(100% + 30px) 2px

    +media('pc')
      margin-top 42px !important
      padding 42px 0

      &::before
        left 0
        size 100% 1px

      &::after
        absolute bottom left
        background #d7d7d7
        content ''
        display block
        size 100% 1px

      .item-inner
        align-items flex-start

        .key
          margin-top 16px

  // .success
  //   .value
  //     &::after
  //       bg-img($image-cmn-path + 'icon-success-1.png', center, top, no-repeat, , cover)
  //       absolute top right _vw(20, $size-sp) bottom
  //       content ''
  //       display block
  //       margin auto 0
  //       size _half(40)
  //
  //     +media('pc')
  //       &::after
  //         right 26px

  .success.item-comment
    .value
      &::after
        bottom auto
        top 12px

  .error
    .input, .textarea, .select-box
      background #ffeff2
      border 1px solid #d90038
      color #d90038
      padding _vw(24, $size-sp) _half(30)

      &::-webkit-input-placeholder
        color #d90038

      &:-ms-input-placeholder
        color #d90038

      &::placeholder
        color #d90038

      +media('pc')
        border-width 2px
        padding 9px 42px 9px 20px

    select
      background #ffeff2

  .submit
    margin-top _half(90)

    +media('pc')
      margin-top 90px

  .button-primary
    width 100%

    +media('pc')
      border-color #000000
      color #000000
      max-width 300px
      width _percentage(300, 720)
      min-width 220px

      .label
        margin-left 0

      .icon
        border-color #000000

        &::before
          border-color #000000

.contact-confirmation
.mw_wp_form_confirm
  .contact-form-inner
    padding 14px _vw(30, $size-sp) _half(90)

    +media('pc')
      padding 47px 60px 90px

    .item
      &:not(:first-child)
        margin-top _half(110)

      +media('pc')
        &:not(:first-child)
          margin-top 52px

      .key, .value
        font-vw(28, 28)

        +media('pc')
          font-rem(14, 14)

        @media only screen and (min-width: $break-point-pc px) and (max-width: 1023px)
          font-rem(12, 12, 80)

      .key
        +media('sp')
          margin-bottom _half(60)

      .value
        font-weight 500

    .item-select-box
      margin-bottom -16px
      padding-bottom _half(114)

      +media('pc')
        margin-bottom 59px
        padding-bottom 58px

    .item-comment
      padding-top _half(82)

      .value
        lineheight(28, 42)

      +media('sp')
        margin-top 40px !important

      +media('pc')
        margin-top 62px !important
        padding 42px 0 72px

        .value
          font-rem(14, 21)
          margin-top 11px

  .submit
    margin-top _half(110)

    +media('pc')
      margin-top 90px

  .buttons
    position relative

    +media('sp')
      margin 0 _vw(30, $size-sp)

  .back
    margin-top 18px

    +media('pc')
      absolute bottom left

    .button
      font-vw(28, 28)
      flex()
      align-items center
      color #1c5a9b
      font-weight 500

      .icon-arrow
        border-color #1c5a9b
        margin-right _half(22)
        relative top 1px
        transform rotate(180deg)

        &::before
          border-color #1c5a9b

      +media('pc')
        font-rem(14, 14)
        transition color 0.5s

        .icon-arrow
          margin-right 7px
          transition border 0.5s
          &::before
            transition border 0.5s

        &:hover
          color #609ad7

          .icon-arrow
            border-color #609ad7

            &::before
              border-color #609ad7

.mw_wp_form_input .contact-form
  .conf-hide
    display inherit
  .conf-show
    display none !important
  .key
    .conf-hide
      display inline-block

.mw_wp_form_confirm .contact-form
  .text-required
    display none
  .required::before
    display none
  .select-box
    background none
    &::before
      display none
  .conf-hide
    display none !important
  .conf-show
    display inherit

@media all and (-ms-high-contrast:none){
  *::-ms-backdrop, .contact-confirmation .back .button .icon-arrow  { top: 0; }
}

@-moz-document url-prefix() {
  .contact-form-inner .input::placeholder {
    font-rem(14, 18, 80)
  }
}
