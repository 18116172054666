.footer
  background #f2f2f2

  .inner
    width _vw(630, $size-sp)
    +media('pc')
      width 100%

.footer-nav
  background #ffffff
  padding 22px 0 45px

  +media('pc')
    padding 60px 0 85px 0

  .button
    color #1c5a9b

  .nav-list
    flex-space-between()
    flex-direction column

    +media('pc')
      flex-direction row

    .nav-list-item
      font-vw(32, 32)
      box-sizing border-box
      font-weight bold

      +media('sp')
        &:not(:first-child)
          border-top 1px solid #d7d7d7

      +media('pc')
        font-rem(15, 15)
        width 20%

      @media only screen and (min-width: $break-point-pc px) and (max-width: 959px)
        font-rem(12, 12)

      .nav-button
        flex()
        align-items center
        color #000000

        +media('sp')
          padding 22px 0

        +media('pc')
          margin-left 18px

        @media only screen and (min-width: $break-point-pc px) and (max-width: 959px)
          margin-left 9px

      .icon-arrow
        margin-right 9px

      &:not(:first-child)
        +media('pc')
          border-left 1px solid #d7d7d7

      &:first-child
        +media('pc')
          .nav-button
            margin-left 2px

      &:last-child
        +media('pc')
          border-left none
          margin-top 62px
          width 100%

        .nav-button
          margin-left 2px

  .subnav-list
    font-vw(28, 28)
    margin 0 0 22px _vw(54, $size-sp)
    font-weight 400

    +media('pc')
      font-rem(12, 12)
      margin 22px 0 0 42px

    @media only screen and (min-width: $break-point-pc px) and (max-width: 959px)
      font-rem(10, 10)
      margin 22px 0 0 32px

    .subnav-list-item
      +media('sp')
        &:not(:first-child)
          margin-top 22px

      +media('pc')
        &:not(:first-child)
          margin-top 14px

  .facilities
    clearfix()

    .area
      +media('sp')
        border-top 1px solid #d7d7d7
        margin 0 0 0 _vw(50, $size-sp)

      +media('pc')
        float left

      .area-head
        position relative
        +media('pc')
          cursor default

        .subheading
          +media('sp')
            font-vw(26, 26)
            padding 16px 0

          +media('pc')
            font-rem(13, 13)
            margin 42px 0 0 0

      // &.is-accordion-opened
      // .subheading
      // .icon-plus
      // &::before
      // transform rotate(180deg)

      // &::after
      // transform rotate(450deg)
      .area-body
        +media('sp')
          display none

        .subnav-list
          +media('sp')
            font-vw(28, 44)
            margin 2px 0 0 0

          +media('pc')
            font-rem(13, 13)
            margin 17px 0 0 0

          @media only screen and (min-width: $break-point-pc px) and (max-width: 959px)
            font-rem(10, 10)

          .subnav-list-item
            +media('sp')
              margin 0 0 14px 0

            +media('pc')
              &:not(:first-child)
                margin-top 18px

      .area-body-inner
        padding-bottom 5px

    .area-tokyo
      +media('pc')
        max-width 768px
        width _percentage(768, $size-content-pc)

      .area-head
        .subheading
          +media('pc')
            margin-left 25px

      .area-body
        .area-body-inner
          +media('pc')
            flex()

        .subnav-list
          +media('pc')
            margin-left 25px

          +media('pc')
            &:not(:first-child)
              margin-left _percentage(120, 768)

          @media only screen and (min-width: $break-point-pc px) and (max-width: 959px)
            &:not(:first-child)
              margin-left _percentage(45, 768)

    .area-oita
      +media('pc')
        // width 300px
        max-width 300px
        width _percentage(300, $size-content-pc)

    .area-fukuoka
      +media('pc')
        margin-top -2px
        // width 300px
        max-width 300px
        width _percentage(300, $size-content-pc)

      .area-head
        .subheading
          +media('pc')
            margin-top 28px

.footer-address
  border-bottom 1px solid #d7d7d7
  padding 45px 0 _half(90) 0

  +media('pc')
    padding 31px 0 29px 0

  .inner
    flex()

  .footer-logo
    margin 0 auto 38px
    width _half(266)

    +media('pc')
      // margin 0 0 0 114px
      margin 0 0 0 _percentage(114, $size-content-pc)
      width 134px

    @media only screen and (min-width: $break-point-pc px) and (max-width: 959px)
      margin 0 0 0 _percentage(114/2, $size-content-pc)

    // @media only screen and (max-width: 1023px)
    //   width 100px

  .address
    font-weight 500
    +media('sp')
      font-vw(28, 54)
      margin 0 auto
      text-align center

      a
        color #1c5a9b

    +media('pc')
      font-rem(13, 20)
      // margin 17px 0 0 56px
      margin 0 0 0 _percentage(56, $size-content-pc)

      a
        pointer-events none

  .sns-list
    centering-flex()
    margin-top _half(42)
    position relative

    +media('pc')
      margin 8px 0 0 2px
      justify-content flex-start

    .item
      &:not(:first-child) 
        margin-left _half(90)
      +media('pc')
        &:not(:first-child) 
          margin-left 18px
        .button
          flex()
          align-items center

      .button
        pointer-events auto

    .icon-sns
      +media('pc')
        margin-right 7px

    .icon-instagram
      size _half(60)
      +media('pc')
        size 20

    .icon-facebook
      size _half(60)
      border-radius 4px
      +media('pc')
        size 20

  .link
    margin 22px auto 0

    +media('pc')
      // margin 15px 114px 0 auto
      margin 15px _percentage(114, $size-content-pc) 0 auto

    @media only screen and (min-width: $break-point-pc px) and (max-width: 959px)
      margin 15px _percentage(114/2, $size-content-pc) 0 auto

    .button-primary
      +media('sp')
        margin 0 auto
        width _vw(570, $size-sp)

      +media('pc')
        width 300px

      @media only screen and (min-width: $break-point-pc px) and (max-width: 959px)
        width 200px

.footer-copyright
  font-vw(20, 20)
  color #000000
  font-family $font-roboto
  font-weight 600
  padding 15px 0
  text-align center

  +media('pc')
    font-rem(10, 10, 60)
    padding 24px 0 20px
