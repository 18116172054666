.button-back-to-top
  box-shadow(0px 4px 5px 0px rgba(0, 0, 0, 0.2))
  centering-flex()
  absolute bottom 30px right 27px
  background #ffffff
  border-radius 50%
  size 60
  z-index 5

  .button
    centering-flex()
    size 100%

  img
    width 24px

  +media('sp')
    right 13px

  &.static
    +media('sp')
      margin _half(60) _half(30) _half(60) auto
      relative right bottom

.button-primary
  font-vw(30, 30)
  border 2px solid #1c5a9b
  box-sizing border-box
  color #1c5a9b
  display block
  font-weight bold
  margin 0 auto
  padding 20px 60px 22px
  position relative
  text-align center

  .icon
    centering-flex()
    icon-arrow-type-1(4, 2, #1c5a9b)
    absolute top bottom right 13px
    border 2px solid #1c5a9b
    border-radius 50%
    box-sizing border-box
    font-size 0
    margin auto 0
    size 30

  +media('pc')
    font-rem(16, 16)
    padding 20px 60px
    transition all 0.3s

    .label
      margin-left -30px

    .icon
      right 10px
      transition all 0.2s ease-in-out

      &::before
        transition all 0.3s

    &:hover
      border-color #609ad7
      color #609ad7

      .icon
        border-color #609ad7
        transform translateX(5px)

        &::before
          border-color #609ad7

.button-secondary
  font-vw(28, 28)
  border 2px solid #000000
  border-radius 20px
  box-sizing border-box
  display block
  font-weight bold
  margin 0 auto
  padding 5px 14px 7px
  position relative
  text-align center

  .icon
    centering-flex()
    icon-arrow-type-1(4, 2, #000000)
    absolute top bottom right 8px
    box-sizing border-box
    font-size 0
    margin auto 0

  +media('pc')
    font-rem(14, 14)

@media all and (-ms-high-contrast none)
  *::-ms-backdrop, .button-secondary
    padding 7px 14px 5px

.button-submit
  padding 28px 60px

  input[type='submit']
    absolute top left
    font-weight bold
    size 100%

.button-tertiary
  font-vw(28, 28)
  background #1c5a9b
  border-radius 20px
  box-sizing border-box
  color #ffffff
  display inline-block
  font-weight bold
  margin 0 auto
  padding 7px 35px 9px
  position relative
  text-align center

  .icon
    centering-flex()
    icon-arrow-type-1(4, 2, #ffffff)
    absolute top bottom right 11px
    box-sizing border-box
    font-size 0
    margin auto 0

  +media('pc')
    font-rem(14, 14)
    padding 7px 49px 9px
