h2.heading, h3.heading, h4.heading, h5.heading, h6.heading
  font-weight bold
  text-align center

.bg-dot-left, .bg-dot-right
  size 873

  &::before
    bg-img($image-cmn-path + 'bg-dot-1.png', left, top, repeat, , 11px)
    absolute top left
    content ''
    display block
    size 100%

  +media('pc')
    &::before
      bg-img($image-cmn-path + 'pc/bg-dot-1.png', left, top, repeat)

.bg-dot-left
  left auto
  top auto

  &::before
    transform rotate(-22deg)

.bg-dot-right
  &::before
    transform rotate(22deg)

.bg-woods
  bg-img($image-cmn-path + 'bg-woods-1.png', center, top, no-repeat, , cover)
  size _half(62)

  +media('pc')
    bg-img($image-cmn-path + 'pc/bg-woods-1.png', center, top, no-repeat, , cover)
    size 45 46

.box-primary
  background #ffffff
  background linear-gradient(135deg, transparent 20px, #ffffff 0) top left, linear-gradient(225deg, transparent 20px, #ffffff 0) top right, linear-gradient(315deg, transparent 20px, #ffffff 0) bottom right, linear-gradient(45deg, transparent 20px, #ffffff 0) bottom left
  background-repeat no-repeat
  background-size 50% 50%
  position relative

.box-primary, .box-inner
  &::before, &::after
    background #000000
    content ''
    display block
    size 54 1
    z-index 1

  +media('pc')
    &::before, &::after
      size 70 1

.box-primary
  &::before
    absolute top 14px left -14px
    transform rotate(135deg)

  &::after
    absolute top 14px right -14px
    transform rotate(45deg)

  +media('pc')
    &::before
      absolute top 15px left -21px
      transform rotate(135deg)

    &::after
      absolute top 15px right -21px
      transform rotate(45deg)

  .box-inner
    &::before
      absolute bottom 14px left -15px
      transform rotate(-135deg)

    &::after
      absolute bottom 14px right -15px
      transform rotate(-45deg)

    +media('pc')
      &::before
        absolute bottom 15px left -22px
        transform rotate(-135deg)

      &::after
        absolute bottom 15px right -22px
        transform rotate(-45deg)

  .box-body
    &::before, &::after
      absolute top right bottom left
      background #fff
      content ''
      display block
      margin auto

    &::before
      size 100% 1px

    &::after
      size 1px 100%

  .box-content
    position relative
    z-index 2

.bottom-img
  bg-img($image-cmn-path + 'img-1.jpg', center, center, no-repeat, , cover)
  // margin-top _half(150)
  position relative
  size 100% _vw(314, $size-sp)

.icon-arrow
  centering-flex()
  icon-arrow-type-1(3, 2, #000000)
  border 2px solid #000000
  border-radius 50%
  box-sizing border-box
  font-size 0
  size 15

  &.blue
    border-color #1c5a9b

    &::before
      border-color #1c5a9b

.icon-plus
  absolute top right _half(16) bottom
  display inline-block
  font-size 0
  margin auto 0
  size 20

  &::before, &::after
    background #000000
    content ''
    display inline-block
    transform rotate(0deg)
    transition all 0.5s ease-in-out

  &::before
    absolute top 9px left 3px
    size 14 2

  &::after
    absolute top 3px left 9px
    size 2 14

.is-accordion-opened
  .icon-plus
    &::before
      transform rotate(180deg)

    &::after
      transform rotate(450deg)

.icon-external
  bg-img($image-cmn-path + 'icon-external-1.png', center, top, no-repeat, , cover)
  absolute top right _vw(24, $size-sp) bottom
  display inline-block
  margin auto 0
  size _half(28)

  +media('pc')
    right 13px
    size 14

.icon-clock
  bg-img($image-cmn-path + 'icon-clock-1.png', center, top, no-repeat, , cover)
  display inline-block
  size _half(48)

  +media('pc')
    size 24

.icon-download
  bg-img($image-cmn-path + 'icon-download-1.png', center, top, no-repeat, , cover)
  display inline-block
  relative top 2px
  size _half(40) _half(36)

  +media('pc')
    size 20 18
    
.entry-box
  background #f4b339
  padding _half(90) _half(60)

  +media('pc')
    padding 30px 0

    .button-primary
      width 300px

      .icon
        right 15px
