

[data-animate="wood"]
  opacity 0
  filter blur(8px)
  transition-delay 0.2s
  &.is-animate
    transition filter 0.8s easeInOutCubic, opacity .2s linear
    opacity 1
    filter blur(0)

[data-animate="fadeIn"]
  opacity 0
  &.is-animate
    transition opacity 1.2s easeInOutSine
    opacity 1

[data-animate="fadeUpIn"]
  opacity 0
  transform translateY(40px)
  &.is-animate
    transition all 1s easeOutCubic
    opacity 1
    transform translateY(0)

[data-animate="item-circle"]
  opacity 0
  transform translateY(40px)
  img
    opacity 0
    backface-visibility hidden
  &.is-animate
    opacity 1
    transform translateY(0)
    transition all 1.2s easeOutCubic
    img
      @extends .slideDownIn
      animation-delay 0.5s
      +media('pc')
        animation-delay 0.8s

$line-opacity
  opacity 0
  transition opacity .5s 1.5s
$base-opacity
  opacity 0
  transition opacity 1s easeInOutSine

$deg45 = 45deg
$deg135 = 135deg

[data-animate="facilities"]
  > .inner
    opacity 0
    transform translateY(30px)
    +media('pc')
      transform translateY(50px)
  .box-primary
    &::before
      transform s('rotate(%s) scaleX(0)', $deg135)
    &::after
      transform s('rotate(%s) scaleX(0)', $deg45)
    .box-inner
      &::before
        transform s('rotate(%s) scaleX(0)', -1 * $deg135)
      &::after
        transform s('rotate(%s) scaleX(0)', -1 * $deg45)
  .heading
  .text-lead
    opacity 0
    transform translateY(20px)
  .item
    opacity 0
  &.is-animate
    > .inner
      opacity 1
      transform translateY(0px)
      transition transform 1s easeOutCubic, opacity .4s
    .box-primary
      &::before
      &::after
        transition transform .4s .7s
      &::before
        transform s('rotate(%s) scaleX(1)', $deg135)
      &::after
        transform s('rotate(%s) scaleX(1)', $deg45)
      .box-inner
        &::before
        &::after
          transition transform .4s .7s
        &::before
          transform s('rotate(%s) scaleX(1)', -1 * $deg135)
        &::after
          transform s('rotate(%s) scaleX(1)', -1 * $deg45)
    .heading,
    .text-lead
      opacity 1
      transform translateY(0)
      transition all 1.5s
    .text-lead
      transition-delay 0.2s
    .item
      opacity 1
      transition opacity 1.5s
      transition-delay 0.2s

[data-animate="recruit"]
  > .inner
    opacity 0
    transform translateY(30px)
    +media('pc')
      transform translateY(50px)
  .box-primary
    &::before
      transform s('rotate(%s) scaleX(0)', $deg135)
    &::after
      transform s('rotate(%s) scaleX(0)', $deg45)
    .box-inner
      &::before
        transform s('rotate(%s) scaleX(0)', -1 * $deg135)
      &::after
        transform s('rotate(%s) scaleX(0)', -1 * $deg45)
  .heading
  .text-lead
  .recruit-link
    opacity 0
    transform translateY(20px)
  &.is-animate
    > .inner
      opacity 1
      transform translateY(0px)
      transition transform 1s easeOutCubic, opacity .4s
    .box-primary
      &::before
      &::after
        transition transform .4s 1.2s
      &::before
        transform s('rotate(%s) scaleX(1)', $deg135)
      &::after
        transform s('rotate(%s) scaleX(1)', $deg45)
      .box-inner
        &::before
        &::after
          transition transform .4s 1.2s
        &::before
          transform s('rotate(%s) scaleX(1)', -1 * $deg135)
        &::after
          transform s('rotate(%s) scaleX(1)', -1 * $deg45)
    .heading,
    .text-lead
    .recruit-link
      opacity 1
      transform translateY(0)
      transition all 1.5s
    .text-lead
      transition-delay 0.2s
    .recruit-link
      transition-delay 0.4s
[data-animate="box"]
  opacity 0
  transform translateY(40px)
  &.is-animate
    opacity 1
    transform translateY(0)
    transition opacity 1.2s easeOutCubic, transform 1s easeOutExpo
[data-animate="slideDownIn"]
  opacity 0
  &.is-animate
    @extends .slideDownIn
    animation-delay 0.5s
[data-animate="slideLeftIn"]
  visibility hidden
  &.is-animate
    @extends .slideLeftIn
[data-animate="slideRightIn"]
  visibility hidden
  &.is-animate
    @extends .slideRightIn
