.modal-block
  display none

.js-modal-loaded
  [data-modal-el='button'], [data-modal-el='youtube']
    cursor pointer
  .js-modal
    display none
    fixed top left
    overflow-y scroll
    size 100%
    z-index 1010
    -webkit-overflow-scrolling touch
    text-align center
    &::before
      content ''
      display inline-block
      height 100%
      vertical-align middle
    &::after
      absolute top right bottom left
      animation spin 0.3s linear infinite
      border 3px solid transparent
      border-radius 24px
      border-right-color #e8e8e8
      border-top-color #e8e8e8
      content ''
      margin auto
      opacity 0
      size 24
      transition opacity 0.3s
    .movie-container
      aspect-ratio-kept-container(16, 9)
      max-width 960px
      margin 0 auto
      .youtube
        absolute top left
        size 100%
    .modal-container
      display inline-block
      position relative
      text-align center
      vertical-align middle
      width 100%

      &::before, &::after
        content ''
        display block
        size 100%, 100

    .modal-contents
      img
        max-width 100%
    .modal-arrows
      absolute top 50%
      height 50px
      transform translateY(-50%)
      width 100%
      .prev, .next
        absolute top
        color transparent
        display block
        font-size 0
        size 50
        &::before
          absolute top 50%
          border-top 1px solid rgba(black, 0.8)
          content ''
          margin-top (- @height / 2)
          size 20
      .prev
        left 0
        &::before
          border-left 1px solid rgba(black, 0.8)
          left 20px
          transform rotate(-45deg)
      .next
        right 0
        &::before
          border-right 1px solid rgba(black, 0.8)
          right 20px
          transform rotate(45deg)
  .js-modal-overlay
    background-color rgba(black, 0.9)
    display none
    fixed top right bottom left
    transform translate3d(0, 0, 0)
    z-index 1009
.is-modal-loading
  .js-modal
    &::after
      opacity 1
      transition-duration 0s

.button-close
  absolute top 49px right 5px
  size 36
