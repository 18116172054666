global-reset()

html
  font-size 62.5%

body
  font-vw(26)
  background-color $base-color
  color $font-color-base
  font-family $font-family-base
  font-feature-settings 'palt'
  text-rendering auto
  -moz-osx-font-smoothing grayscale
  -webkit-font-smoothing antialiased
  -webkit-tap-highlight-color rgba(0, 0, 0, 0)
  -webkit-text-size-adjust 100%

  +media('pc')
    font-rem(14)

img
  height auto
  max-width 100%
  vertical-align bottom
  width 100%

  +media('pc')
    width auto

a
  color $link-color-base
  text-decoration none

strong
  font-weight bold

sup
  font-size 75.5%
  position relative
  top -0.1em
  vertical-align top

button, input, select, textarea
  font-family inherit
  font-size 100%
  outline none

button, input[type='image'], input[type='submit'], label[for], select
  cursor pointer

button
input[type='submit']
  background none
  border 0
  border-radius 0
  margin 0
  padding 0
  -moz-appearance none
  -webkit-appearance none

select::-ms-expand
  display none

main
  background #d8f5f6
  display block
  overflow hidden
  padding-top _half(120)
  position relative

  +media('pc')
    padding-top 133px

  @media only screen and (min-width: $break-point-pc px) and (max-width: 1023px)
    padding-top 103px

body:not(.page-top)
  +media('pc')
    main
      padding-top 78px
