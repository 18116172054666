/*! ================================
 * accordion
 * ================================*/
[data-accordion]
  [data-accordion-el="button"]
    cursor pointer
  [data-accordion-el="contents"]
    overflow hidden
    height 0
    transition height .7s easeOutCirc

.js-accordion-sp
  +media('pc')
    [data-accordion-el="button"]
      pointer-events none
