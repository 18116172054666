.hero-content
  color #000000
  position relative

.hero-heading
  font-weight bold

// Hero背景（ピンク、グレー、水色、水玉）
.hero-bg
  absolute top left
  background #ffffff
  overflow hidden
  size 100% 100vh // 高さは個別CSSで上書き

.bg-pink
  absolute top bottom left -34.89%
  margin auto 0
  size 50% 200vh

  &::before
    background #ffe5ea
    content ''
    display block
    size 100%
    transform skewX(21deg)

  +media('sp')
    left -53.8%

.bg-gray
  absolute top left 50%
  border-color transparent #f2f2f2 transparent transparent
  border-style solid
  border-width 0 1608px 438px 0
  margin-left _percentage(-356, $size-pc)
  size 0

  +media('sp')
    top -8px

.bg-blue
  absolute right bottom left
  background #d8f5f6
  margin 0 auto
  size 100% 68

  &::before
    absolute top left
    border-color transparent transparent #d8f5f6 transparent
    border-style solid
    border-width 0 0 456px 2500px
    content ''
    display block
    transform translateY(-100%)

// .bg-dot-container
// absolute top -1.68% left
// overflow hidden
// size 100% 873
// +media('pc')
// top -2.15%

.bg-dot-container
  absolute top left
  min-height 100%
  overflow hidden
  width 100%

.bg-dot
  absolute top -15.5% left _percentage(1500, $size-pc)
  size 873

  &::before
    bg-img($image-cmn-path + 'bg-dot-1.png', left, top, repeat, , 11px)
    absolute top left
    content ''
    display block
    size 100%
    transform rotate(22deg)

  +media('pc')
    left _percentage(1213, $size-pc)
    top -1.5%

    &::before
      bg-img($image-cmn-path + 'pc/bg-dot-1.png', left, top, repeat)
