// ==========================================================================
// 下層用 採用情報
// ==========================================================================
body:not(.page-top)
  .section-recruit
    position relative
    .bg
      bg-img($image-cmn-path + 'bg-recruit-1.jpg', center, top, no-repeat, , cover)
      size 100% _vw(476, $size-sp)

      +media('pc')
        bg-img($image-cmn-path + 'pc/bg-recruit-1.jpg', center, center, no-repeat, , cover)
        background-attachment fixed
        height 400px

      @media only screen and (min-width: $break-point-pc px) and (max-width: $break-point-pc px)
        background-attachment scroll

    .box
      container-centering(_vw(690, $size-sp))
      background #fff
      box-sizing border-box
      margin-top _vw(-92, $size-sp)
      padding 43px _vw(58, $size-sp) _half(90)
      text-align center

      +media('pc')
        max-width 840px
        width _percentage(840, $size-content-pc)
        margin-top -140px
        padding 44px 40px 48px

      .recruit-heading
        font-vw(46, 46)
        font-weight bold
        margin-bottom 17px

        +media('pc')
          font-rem(30, 30)
          margin-bottom 26px

      .text
        font-vw(28, 44)
        margin-bottom _half(50)

        +media('pc')
          font-rem(16, 24)
          margin-bottom 35px
 
      .button-primary
        +media('pc')
          width 300px
         .icon
            right 12px

      .img-web-interview
        absolute top _vw(-138, $size-sp) right _vw(-14, $size-sp)
        width _vw(270, $size-sp)

        +media('pc')
          absolute top -30px right -30px
          max-width 200px
          width _percentage(200, 840)