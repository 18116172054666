.sticky-container
  relative top
  margin 0
  width 100%

.sticky-inner
  absolute top left
  width 100%
  padding 18px 0

  +media('pc')
    padding 30px 0

  &.is-sticky
    fixed top left
    z-index 10

.recruit-nav
  box-shadow(3px 4px 5px 0px rgba(3, 0, 0, 0.25))
  container-centering(_vw(690, $size-sp))
  // absolute top _vw(1106, $size-sp) right left
  // absolute top left
  background #1c5a9b
  border-radius 3px
  box-sizing border-box
  color #ffffff
  margin 0 auto
  overflow hidden
  padding 0
  z-index 1001

  +media('pc')
    background #3a75b2
    border-radius 6px
    max-width $size-content-pc px
    // top 588px
    width _percentage($size-content-pc, $size-pc)

  a
    color #ffffff

  .recruit-nav-head
    font-vw(28, 28)
    padding _half(40) 0
    position relative
    text-align center

  .recruit-menu-button
    absolute top right
    box-sizing border-box
    display block
    font-size 0
    size _half(100) _half(110)
    z-index 10

    .line
      absolute right left
      background #5f8dbd
      display block
      font-size 0
      margin 0 auto
      size _half(42) 1
      transition all 0.3s

      &:nth-child(1)
        top _half(42)

      &:nth-child(2)
        top _half(52)

      &:nth-child(3)
        top _half(62)

  .recruit-nav-body
    +media('sp')
      display none

  .recruit-nav-list
    flex()
    flex-wrap nowrap

    +media('sp')
      flex-direction column
      padding 0 _vw(90, $size-sp)

    .nav-list-item
      font-vw(28, 28)
      box-sizing border-box
      font-weight 500
      position relative

      +media('pc')
        font-rem(14, 14)
        max-width 144px
        width _percentage(144, $size-content-pc)

        &::before
          absolute top bottom left
          background #9cdced
          content ''
          display block
          margin auto 0
          size 1 18

      @media only screen and (min-width: $break-point-pc px) and (max-width: 959px)
        font-rem(11, 11)


    .button
      display block
      text-align center

      +media('sp')
        border-top 1px solid #5f8dbd
        // padding 0 _vw(-90, $size-sp)
        padding 20px 0

      +media('pc')
        padding 23px 0

    .nav-list-item:nth-child(1)
      +media('pc')
        position relative
        width 60px

        &::before
          display none

        .button
          padding 17px 0

    .nav-list-item:nth-child(6)
      +media('pc')
        &::before
          display none

      +media('sp')
        .button
          border none

    .nav-list-item:nth-child(n+6)
      background #1c5a9b

      +media('sp')
        margin 0 _vw(-90, $size-sp)

        .button
          margin 0 _vw(90, $size-sp)

    .located.nav-list-item
      +media('sp')
        .button
          &::before
            // absolute top bottom left _vw(88, $size-sp)
            absolute top bottom left
            border-color transparent transparent transparent #5f8cbd
            border-style solid
            border-width 5px 0 5px 9px
            content ''
            display block
            margin auto 0
            size 0

      +media('pc')
        .button
          &::before
            absolute right bottom 8px left
            border-color transparent transparent #fff transparent
            border-style solid
            border-width 0 4px 8px 4px
            content ''
            display block
            margin 0 auto
            size 0

    .located.nav-list-item:nth-child(n+6)
      +media('sp')
        .button
           &::before
             left _vw(88, $size-sp)


.recruit-nav-overlay
  background rgba(#000, 0.85)
  display none
  fixed top left
  size 100% 100vh
  z-index 1001

.is-recruit-menu-opened
  .menu-button
    +media('sp')
      z-index 1001
  .recruit-nav-overlay
    display block

  .recruit-nav
    background #3a75b2

  .recruit-menu-button
    .line
      width _half(42)

      &:nth-child(1)
        transform translate(-2px, 5px) rotate(135deg)

      &:nth-child(2)
        opacity 0

      &:nth-child(3)
        transform translate(-2px, -5px) rotate(-135deg)
