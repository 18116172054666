.page-shisetsu
  .sticky-container
    relative top
    margin 0
    width 100%

    .bg-woods
      +media('pc')
        absolute right top 842px left
        margin 0 auto

  .sticky-inner
    absolute top left
    padding 18px 0
    order 1
    width 100%
    // height auto

    +media('pc')
      padding 30px 0
      max-width 198px

    &.is-sticky
      fixed top
      left auto
      width _vw(690, $size-sp)
      z-index 10

      +media('pc')
        width _percentage(198, $size-pc)

    &.is-bottom-sticky
      absolute bottom left
      top auto

  .shisetsu-nav
    box-shadow(3px 4px 5px 0px rgba(3, 0, 0, 0.25))
    // container-centering(_vw(690, $size-sp))
    position relative
    width 100%
    // absolute top _vw(1106, $size-sp) right left
    // absolute top left
    background #1c5a9b
    border-radius 3px
    box-sizing border-box
    color #ffffff
    margin 0 auto
    overflow hidden
    padding 0
    z-index 11

    +media('pc')
      border-radius 6px
      width 100%
      // max-width 198px
      // width _percentage(198, $size-pc)

    a
      color #ffffff

    .shisetsu-nav-head
      font-vw(28, 28)
      padding _half(40) 0
      position relative
      text-align center
      font-weight 500

    .shisetsu-menu-button
      absolute top right
      box-sizing border-box
      display block
      font-size 0
      size _half(100) _half(110)
      z-index 10

      .line
        absolute right left
        background #5f8dbd
        display block
        font-size 0
        margin 0 auto
        size _half(42) 1
        transition all 0.3s

        &:nth-child(1)
          top _half(42)

        &:nth-child(2)
          top _half(52)

        &:nth-child(3)
          top _half(62)

    .shisetsu-nav-body
      +media('sp')
        display none

    .shisetsu-nav-list
      // flex()

      +media('sp')
        // flex-direction column
        padding 0 _vw(90, $size-sp)

      .nav-list-item
        font-vw(28, 28)
        box-sizing border-box
        position relative
        font-weight 500

        +media('pc')
          font-rem(14, 14)
          // max-width 144px
          // width _percentage(144, $size-content-pc)

          // &::before
          //   absolute top bottom left
          //   background #9cdced
          //   content ''
          //   display block
          //   margin auto 0
          //   size 1 18

        @media only screen and (min-width: $break-point-pc px) and (max-width: 959px)
          font-vw(10, 10)

      .button
        display block
        text-align center
        border-top 1px solid #5f8dbd

        +media('sp')
          // border-top 1px solid #5f8dbd
          // padding 0 _vw(-90, $size-sp)
          padding 20px 0

        +media('pc')
          padding 20px 0
          margin 0 _percentage(30, 198)

      // .nav-list-item:nth-child(1)
      //   +media('pc')
      //     position relative
      //     width 60px

      //     &::before
      //       display none

      //     .button
      //       padding 17px 0

      // .nav-list-item:nth-child(6)
      //   +media('pc')
      //     &::before
      //       display none

      //   +media('sp')
      //     .button
      //       border none

      // .nav-list-item:nth-child(n+6)
      //   background #1c5a9b

      //   +media('sp')
      //     margin 0 _vw(-90, $size-sp)

      //     .button
      //       margin 0 _vw(90, $size-sp)

      .located.nav-list-item
        +media('sp')
          .button
            &::before
              absolute top bottom left
              border-color transparent transparent transparent #5f8cbd
              border-style solid
              border-width 5px 0 5px 9px
              content ''
              display block
              margin auto 0
              size 0

        +media('pc')
          .button
            position relative
            &::before
              absolute top bottom left
              border-color: transparent transparent transparent #ffffff;
              border-style solid
              border-width: 5px 0 5px 8px;
              content ''
              display block
              margin auto 0
              size 0

      .nav-list-item:nth-child(1)
        .button
          +media('pc')
            border-top none

  .shisetsu-nav-overlay
    background rgba(#000, 0.85)
    display none
    fixed top left
    size 100% 100vh
    z-index 10

.is-recruit-menu-opened
  .page-shisetsu
    .shisetsu-nav-overlay
      display block

    .shisetsu-menu-button
      .line
        width _half(42)

        &:nth-child(1)
          transform translate(-2px, 5px) rotate(135deg)

        &:nth-child(2)
          opacity 0

        &:nth-child(3)
          transform translate(-2px, -5px) rotate(-135deg)
