// ==========================================================================
// Header
// ==========================================================================
.header
  absolute top left
  width 100%

.header-container
  box-sizing border-box
  size 100% _half(120)

  +media('pc')
    height 133px

    &.is-sticky
      position fixed !important
      top 0
      height auto
      z-index 2

  @media only screen and (min-width: $break-point-pc px) and (max-width: 959px)
    height 103px

.header-inner
  flex()
  height 100%
  z-index 2

  +media('pc')
    container-centering(_percentage($size-content-pc, $size-pc))

.header-logo
  absolute top _half(23) left _half(25)
  font-size 0
  z-index 1

  // +media('sp')
  // .is-menu-opened &
  // position fixed
  +media('pc')
    absolute top 22px left 1px

  .img
    bg-img($image-cmn-path + 'logo-1.png', center, top, no-repeat, , cover)
    text-replace()
    display block
    size _half(292) _half(73)

    +media('pc')
      size 268 67

    @media only screen and (min-width: $break-point-pc px) and (max-width: 1023px)
      size 168 42

// ==========================================================================
// SP Menu Button
// ==========================================================================
+media('sp')
  .menu-button
    absolute top right
    box-sizing border-box
    display block
    font-size 0
    size _half(134) _half(120)
    z-index 1003

    .text
      bg-img($image-cmn-path + 'menu-text-1.png', center, top, no-repeat, , cover)
      absolute top _half(29) right left
      display block
      font-size 0
      margin 0 auto
      size _half(50) _half(14)
      transition opacity 0.2s

    .line
      absolute right left
      background #1c5a9b
      display block
      font-size 0
      margin 0 auto
      size _half(48) 2
      transition all 0.3s

      &:nth-child(1)
        top _half(54)

      &:nth-child(2)
        top _half(70)

      &:nth-child(3)
        top _half(86)

    .is-menu-opened &
      // position fixed
      .text
        opacity 0

      .line
        width _half(60)

        &:nth-child(1)
          transform translate(-2px, 2px) rotate(135deg)

        &:nth-child(2)
          opacity 0

        &:nth-child(3)
          transform translate(-2px, -14px) rotate(-135deg)

// ==========================================================================
// SP Fullscreen Nav
// ==========================================================================
+media('sp')
  .nav
    background rgba(#000000, 0.6)
    display none
    fixed top right bottom left
    margin auto
    overflow hidden
    overflow-y scroll
    size 100%
    z-index 1002
    -webkit-overflow-scrolling touch

    .nav-inner
      background #fff
      box-sizing border-box
      overflow hidden
      padding _half(120) _vw(90, $size-sp) _half(254)
      position relative
      width 100%

    .nav-bg
      absolute top left
      size 100%

      .bg-pink
        absolute top bottom left -55.5%
        margin auto 0
        size 50% 200vh

        &::before
          background #ffe5ea
          content ''
          display block
          size 100%
          transform skewX(21deg)

        +media('sp')
          left -52.8%

      .bg-gray
        absolute top -1% left 50%
        border-color transparent #f2f2f2 transparent transparent
        border-style solid
        border-width 0 1608px 438px 0
        margin-left _percentage(-356, $size-pc)
        size 0

        +media('sp')
          top -8px

      .bg-blue
        absolute right bottom left
        background #d8f5f6
        margin 0 auto
        // size 100% 68
        size 100% 85

        &::before
          absolute top left
          border-color transparent transparent #d8f5f6 transparent
          border-style solid
          border-width 0 0 456px 2500px
          content ''
          display block
          transform translateY(-100%)

      .bg-woods
        absolute bottom _vw(146, $size-sp) left _vw(28, $size-sp)

    .nav-logo
      absolute top _half(20) left _half(22)
      width _half(292)

    .nav-list
      border-bottom 1px solid #b5cdce
      margin-top _half(92)
      position relative
      text-align center

    .nav-list-item
      font-vw(34, 34, 200)
      border-top 1px solid #b5cdce
      color #ffffff
      font-weight 500

      a
        color #000000
        display block
        padding 21px 0

    .banner-list
      margin-top _half(66)
      position relative

      .banner-list-item
        &:not(:first-child)
          margin-top _half(66)

    .is-menu-opened &
      display block

@media only screen and (min-width: 415px) and (max-width: 767px)
  .nav
    .nav-list-item
      font-rem(34 / 2)

// ==========================================================================
// PC Horizonta Nav
// ==========================================================================
+media('pc')
  .nav, .nav-inner, .nav-list, .nav-list-item
    height 100%

  .nav
    box-sizing border-box
    margin 0 -3px 0 auto
    relative top -1px

    .nav-list
      flex()

    .nav-list-item
      flex()
      font-rem(14, 14, 100)
      align-items center
      color #000000
      font-weight 500
      position relative
      margin 0 1px

      a
        padding 10px 15px 10px 19px
        transition color 0.3s

        &:hover
          color #1c5a9b

      &:not(:first-child)
        &::before
          absolute top bottom left
          background #1c5a9b
          content ''
          display block
          margin auto 0
          size 1 10

      &:last-child
        a
          padding 10px 0 10px 20px

    .nav-list-item.located
      a
        color #1c5a9b


@media only screen and (min-width: $break-point-pc px) and (max-width: 1023px)
  .nav
    .nav-list-item
      font-rem(13, 13, 10)

      a
        padding 10px 10px 10px

        &:last-child
          padding 10px 10px 10px

// ==========================================================================
// PC 下層用Header/追従Header
// ==========================================================================
body:not(.page-top)
  +media('pc')
    .header-container
      background rgba(#ffffff, 0.85)
      border-bottom 1px solid #eeeeee
      height 77px
      position relative
      z-index 1

      .header-logo
        top 18px

        .img
          size 168 42

      .nav
        top 4px

        .nav-list
          .nav-list-item
            &:not(:first-child)
              &::before
                transform translateY(2px)

.is-sticky
  +media('pc')
    &.header-container
      background rgba(#ffffff, 0.85)
      border-bottom 1px solid #eeeeee
      height 77px

      .header-logo
        top 18px

        .img
          size 168 42

      .nav
        top 4px

        .nav-list
          .nav-list-item
            &:not(:first-child)
              &::before
                transform translateY(2px)
