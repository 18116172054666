// ==========================================================================
// 財務諸表等（PDF）
// ==========================================================================
.section-statement
  margin-bottom 63px
  margin-top -30px
  padding-top 30px

  +media('pc')
    margin-bottom 136px
    margin-top -100px
    padding-top 100px
    
  .heading
    font-vw(46, 46)
    margin-bottom 30px

    .small
      font-rem(14)
      display block
      font-weight normal
      margin-top 8px

    +media('pc')
      font-rem(24, 24)

  .statement-list
    border-bottom 1px solid #b5cdce
    margin-bottom 19px

    +media('pc')
      container-centering(840)

    .item
      font-vw(28, 54)
      border-top 1px solid #b5cdce
      padding 8px 0

      +media('pc')
        font-rem(14, 24)
        padding 13px 0

      a
        color #1c5a9b
        transition color 0.5s

        &:hover
          color #609ad7

  .statement-links
    display flex

    +media('pc')
      container-centering(840)

    .item
      font-vw(28, 42)

      &:not(:first-child)
        margin-left 40px

      +media('pc')
        font-rem(14, 24)

        &:not(:first-child)
          margin-left 28px

      .item-inner
        align-items center
        color #1c5a9b
        display flex
        transition color 0.5s ease-in-out

        .icon
          centering-flex()
          icon-arrow-type-1(4, 2, #1c5a9b)
          border 2px solid #1c5a9b
          border-radius 50%
          box-sizing border-box
          font-size 0
          margin 0 10px 0 0
          size 15
          transition border 0.5s ease-in-out

          &::before
            size 3
            transition border 0.5s ease-in-out

          +media('pc')
            margin 0 6px 0 0

        &:hover
          color #609ad7

          .icon
            border-color #609ad7

            &::before
              border-color #609ad7