// ==========================================================================
// utitlity
// ==========================================================================
+media('sp')
  .sp
    display block

    img&
      display inline

  .pc
    display none

+media('pc')
  .pc
    display block

    img&
      display inline

  .sp
    display none

.flex
  flex()

.text-justify
  text-justify()

.text-center
  text-align center

.text-right
  text-align right

.text-left
  text-align left

.text-underline
  text-decoration underline

.vertical-top
  vertical-align top

.vertical-middle
  vertical-align middle

.vertical-bottom
  vertical-align bottom

.second-line-indent
  padding-left 1em
  text-indent -1em
  
.inner
  container-centering(_vw($size-content-sp, $size-sp))

  +media('pc')
    max-width $size-content-pc px
    width _percentage($size-content-pc, $size-pc)

lock-screen()

.ruby-sinyukai
  margin-left auto
  margin-right auto
  position relative

  &::before
    font-vw(18, 18, 200)
    absolute top -13px left
    content 'しんゆうかい'
    display block

