.breadcrumb
  margin 10px 0 0
  position relative
  z-index 1

  +media('pc')
    margin 18px 0 0 2px

.breadcrumb-list
  flex()
  container-centering(_vw($size-content-sp, $size-sp))

  +media('pc')
    width _percentage($size-content-pc, $size-pc)

.breadcrumb-list-item
  font-vw(24, 34)
  color #000000
  position relative

  &:not(:first-child)
    margin-left 2px
    padding-left 9px

    &::before
      absolute top bottom left
      content '>'
      display block
      margin auto 0

  +media('pc')
    font-rem(12, 22)

  a
    color #1c5a9b
    +media('pc')
      transition color 0.5s 
      &:hover
        color #609ad7
